import React from 'react';
import { useEffect} from 'react'
import  Routes  from "./Routes"
import "./App.css";
import AOS from "aos";
import "aos/dist/aos.css";
import GoToTop from "../src/GoToTop";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
const App = () => {
  useEffect(() => {
    AOS.init({
      once: true,
      disable: "phone",
      duration: 700,
      easing: "ease-out-cubic",
    });
  }, []);
  return (
    <React.Fragment>
      <Routes/>
      <ToastContainer/>
      <GoToTop /> 
    </React.Fragment>
  );
};

export default App;