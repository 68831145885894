import React from "react";
import "./Portfolio.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Navbar from "../../Comman/Header/Navbar";
import Footer from "../../Comman/Footers/Footer";
import { FaAppleAlt, FaGlobe, FaHtml5, FaCss3Alt, FaReact,
     FaBootstrap, FaNodeJs } from "react-icons/fa";
import { FcAndroidOs } from "react-icons/fc";
// import aap11 from "../../assets/images/portfolio/aap11.png";
import { DiMongodb } from "react-icons/di";

function Portfoliodetails() {
  return (
    <>
      <Navbar />
      <section className="project-details texture-bg-2 pt-120">
        <Container>
          <Row className="pt-120">
            <Col md={12} xl={12}>
              <div className="caseStudyDetail">
                <h2>An On-Demand Grocery Delivery Application</h2>
                <div className="project-two mb-4">
                <h2 className="tcp-1">Sound chat radio </h2>
                <p>
                  Sound chat radio is a comprehensive on-demand grocery delivery
                  app that allows users to shop for groceries to daily
                  necessities and get it delivered on the doorsteps. The user
                  can directly explore the variety of grocery items and order
                  them at their convenience. After getting the order, the items
                  are picked up by the delivery person and delivered to the
                  mentioned delivery address. For consumers, various payment
                  options are available to ensure easy checkout including cash
                  on arrival, e-wallets, credit and debit cards, net banking,
                  etc.
                </p>
                <a className="dt-link" href="https://soundchatradio.com/">https://soundchatradio.com/</a>
              </div>
              
                <ul className="tagList">
                  <li> PLANNING</li>
                  <li>ANALYSIS</li>
                  <li>UX/UI DESIGN</li>
                  <li>APP/WEB DEVELOPMENT</li>
                </ul>
              </div>
              <ul className="platform">
                <li className="black">
                  <span>
                    <FaAppleAlt />
                  </span>
                  iOS
                </li>
                <li className="green">
                  <span>
                    <FcAndroidOs
                      style={{
                        fontSize: "25px",
                        marginRight: "10px",
                        marginLeft: "10px",
                      }}
                    />
                  </span>
                  Android
                </li>
                <li className="purple">
                  <em className="icon-web">
                    <FaGlobe />
                  </em>
                  Website
                </li>
              </ul>
            </Col>
           
          </Row>
        </Container>
      </section>

      <section className="project-two-section texture-bg-2 mt-4">
        <Container>
          <Row>
          <Col lg={6} md={12}>
              {/* <div className="project-new-img">
                <img src={aap11} />
              </div> */}
            </Col>
            <Col lg={6} md={12}>
              <div className="aboutSec_rightBox">
                <ul className="diversityList list-unstyled">
                <li>
                    <span className="diversityList_title">Client</span>
                    <p>Sheldon B.</p>
                  </li>
                  <li>
                    <span className="diversityList_title">Client’s Location</span>
                    <p>139-20 C 109th Ave, Jamaica, NY - 11435</p>
                  </li>
                  <li>
                    <span className="diversityList_title">Development Time</span>
                    <p>06 Months</p>
                  </li>
                  <li>
                    <span className="diversityList_title">Target Users</span>
                    <p>
                    Music,  Ecommerce brands, users to shop for groceries to daily
                      necessities
                    </p>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="project-third texture-bg-2 pb-120">
        <Container>
          <Row>
            <Col md={12}>
              <div className="project-laguage">
                <h2>Technologies Leveraged</h2>
                <p className="text-white">We leveraged the strength of robust and secure technologies, tools, and
                  frameworks used in the app/web
                  to escalate the creation of new features and functionalities.</p>
              </div>
              <Row>
                <Col lg={2} md={4} sm={6} xs={6}>
                  <div className="leveragedSec_cnt">
                    <i className="html">
                      <FaHtml5 />
                    </i>
                    <h5>Html5</h5>
                  </div>
                </Col>
                
                <Col lg={2} md={4} sm={6} xs={6}>
                  <div className="leveragedSec_cnt">
                    <i className="css">
                      <FaCss3Alt />
                    </i>
                    <h5>Css3</h5>
                  </div>
                </Col>

                <Col lg={2} md={4} sm={6} xs={6}>
                  <div className="leveragedSec_cnt">
                    <i className="react">
                      <FaReact />
                    </i>
                    <h5>React js</h5>
                  </div>
                </Col>

                <Col lg={2} md={4} sm={6} xs={6}>
                  <div className="leveragedSec_cnt">
                    <i className=" bootstrap">
                      < FaBootstrap />
                    </i>
                    <h5>Bootstrap</h5>
                  </div>
                </Col>

                <Col lg={2} md={4} sm={6} xs={6}>
                  <div className="leveragedSec_cnt">
                    <i className="node">
                      <  FaNodeJs />
                    </i>
                    <h5>Node js</h5>
                  </div>
                </Col>

                <Col lg={2} md={4} sm={6} xs={6}>
                  <div className="leveragedSec_cnt">
                    <i className="Mongodb">
                      <  DiMongodb />
                    </i>
                    <h5>Mongodb</h5>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </>
  );
};

export default Portfoliodetails;
