import React, { useState, useEffect, useRef } from "react";
import Pagination from "react-bootstrap/Pagination";
import { FaMapMarkerAlt } from "react-icons/fa";
import { FaRegClock } from "react-icons/fa";
import { FaUserTie } from "react-icons/fa";
import "aos/dist/aos.css";
import AOS from "aos";
import Modal from "react-bootstrap/Modal";
import "./career.css";
import { useFormik } from "formik";
import axios from "axios";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Swal from "sweetalert2";
import career from "../../assets/images/career.png";
import career1 from "../../assets/images/career1.png";
import Careerform from "./Careerform";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import { ThreeDots } from "react-loader-spinner";

const Careers = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  //loder
  const { promiseInProgress } = usePromiseTracker();

  // state
  const [show, setShow] = useState(false);
  const [titlejob, setTitleJob] = useState("");

  // Create a ref for the file input
  const fileInputRef = useRef(null);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //   pagination
  let active = 2;
  let items = [];
  for (let number = 1; number <= 5; number++) {
    items.push(
      <Pagination.Item key={number} active={number === active}>
        {number}
      </Pagination.Item>
    );
  }

  // toast message function
  const showToastMessage = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const showToastMessageone = (data) => {
    toast.error(data, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

// sweetalert funcation
  const showAlert = (message) => {
    Swal.fire({
      title: "Thank You !",
      text: message,
      icon: "success",
      confirmButtonText: "OK",
    });
  };

  //this is uses for the pdf/image upload in database.
  const [resumefile, setResumefile] = useState("");

  // image upload validation function
  const upload = (event) => {
    const imageFile = event.target.files[0];
    setResumefile(imageFile);
    if (!imageFile) {
      showToastMessage("Please select a file");
      return false;
    } else if (
      !imageFile.name.match(/\.(jpg|jpeg|png|PNG|JPG|JPEG|doc|docx|pdf|txt)$/)
    ) {
      showToastMessage("Please select only .pdf, .txt, .doc");
      return false;
    }
  };

  // api calling applyforjob
  function handleSubmit(values) {
    const bodyFormData = new FormData();
    bodyFormData.append("name", values.name);
    bodyFormData.append("email", values.email);
    bodyFormData.append("job", titlejob);
    bodyFormData.append("phone", values.phone);
    bodyFormData.append("message", values.message);
    bodyFormData.append("file", resumefile);
    const url = `${process.env.REACT_APP_BASE_URL}/applyforjob`;
    trackPromise(
      axios
        .post(url, bodyFormData, {
          "Content-Type": "multipart/form-data",
          headers: { Accept: "application/json" },
        })
        .then((response) => {
          if (response.data.status_code === 200) {
            setResumefile("");
            if (fileInputRef.current) {
              fileInputRef.current.value = null;
            }
            showAlert(response.data.message);
            setShow(false);
          } else if (response.data.status_code === 201) {
            showToastMessageone(response.data.message.name);
            showToastMessageone(response.data.message.email);
            showToastMessageone(response.data.message.job);
            showToastMessageone(response.data.message.phone);
            showToastMessageone(response.data.message.message);
            showToastMessageone(response.data.message.file);
          }
        })
        .catch((error) => {
          console.log(error);
        })
    )
  }

  // useformik function  
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      message: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .matches(/^[A-Za-z\s]+$/, "Only Enter Alphabet")
        .required("Required!"),

      email: Yup.string()
        .email("Invalid email format")
        .matches(/^\S*$/, "Empty Whitespace Not Allowed")
        .required("Required!"),

      phone: Yup.string()
        .required("Required!")
        .matches(
          /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
          "Phone number is not valid"
        ),
      message: Yup.string().required("Required!"),
    }),
    onSubmit: (values) => {
      setResumefile("");
      if (fileInputRef.current) {
        fileInputRef.current.value = null;
      }
      handleSubmit(values);
      formik.resetForm();
    },
  });


  // api calling getcareerjobs
  const [jobdata, setJobdata] = useState([]);

  useEffect(() => {
    trackPromise(
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/getcareerjobs`)
        .then((response) => {
          if (response.status == 200) {
            setJobdata(response.data.jobslist);
          }
        })
        .catch((error) => {
          console.log(error);
        })
    )
  }, []);

  return (
    <>
      <section className="career pt-120 pb-120 texture-bg-2">
        <Container>
          <Row className="g-6 align-items-center justify-content-between pt-120">
            <Col lg={8} md={6}>
              <div className="career-mn" data-aos="fade-left" data-aos-duration="1500">
                <h4 className="display-four tcp-1">Let’s get started</h4>
                <h5>Are you ready for a better, more productive business?</h5>
                <p>
                  Career and Culture at Impetrosys We Provide All Your Needs!
                  Software Consultant || IT Expert || Online Business Solution
                  || Mobile & Web Apps || Products Development
                </p>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="career" data-aos="fade-right" data-aos-duration="1500">
                <img src={career} alt="carre" className="w-100" />
              </div>
            </Col>
          </Row>
          <Row className="mt-5 meet-carrer">
            <Col md={4}>
              <div className="career" data-aos="fade-left" data-aos-duration="1800">
                <img src={career1} alt="carre" className="w-100" />
              </div>
            </Col>
            <Col md={8}>
              <div className="career-mnn" data-aos="fade-right" data-aos-duration="1800">
                <h3>Meet the Impetrosys Constellation</h3>
                <p>
                  At IMPETROSYS, we recognize that exceptional talent stands out
                  from the rest, and that the demand for sustainable speed has
                  never been higher. Our culture fosters career-integrated
                  hyper-learning and builds extraordinary relationships, driving
                  impactful results.
                </p>
                <p>
                  Join our network of diverse, exceptional individuals who seek
                  equality and challenge, driven by a continuous pursuit of new
                  ideas and learning
                </p>
                <p>
                  IMPETROSYS comprises the brightest minds working with
                  cutting-edge technologies, dedicated to creating meaningful,
                  positive changes in an increasingly digital world.
                </p>
              </div>
            </Col>
          </Row>
          <div className="vacancy pt-120">
            <div className="container">
              <div className="text-center mb-2" data-aos="fade-down">
                <h4 className="display-four tcp-1">Jobs opening</h4>
                <p className="lead">Grow your career with us</p>
              </div>
              <div className="row">
                      {jobdata.map((element, index) => {
                        return (
                          <div className="col-md-6 col-lg-4" key={index} data-aos="fade-up">
                            <div className="career-item">
                              <div className="career-text">
                                <div className="career-title-box">
                                  <h3 className="title">{element.title}</h3>
                                  <p>{element.description}</p>
                                </div>
                                <div className="job-portal">
                                  {element.category.map((category, index) => {
                                    return (
                                      <span
                                        key={index}
                                        className="badge bg-secondary">
                                        {category}
                                      </span>
                                    );
                                  })}
                                </div>
                                <ul className="course-meta-details">
                                  <li className="caree-usr">
                                    <span>
                                      <FaUserTie />
                                    </span>
                                    <p>{element.experience}</p>
                                  </li>
                                  <li className="caree-usr">
                                    <span>
                                      <FaRegClock />
                                    </span>
                                    <p>{element.job_type}</p>
                                  </li>
                                  <li>
                                    <span>
                                      <FaMapMarkerAlt />
                                    </span>
                                    <p>{element.job_location}</p>
                                  </li>
                                </ul>
                                <a
                                  onClick={() => {
                                    setShow(true);
                                    setTitleJob(element.title);
                                  }}
                                  className="carrer-btn">
                                  Apply
                                </a>
                              </div>
                            </div>
                          </div>
                        );
                      })}
              </div>
            </div>
            <ToastContainer />
          </div>
          <div className="career-benifit pt-120">
            <Row>
              <Col lg={6} md={12} data-aos="fade-up">
                <h4 className="display-four">Benefit’s</h4>
                <p className="mb-3">
                  In today's technology sector, professionals understand the
                  necessity of future-proofing their skills. Traditional
                  learning methods alone are insufficient. Digital innovations,
                  evolving digital-native needs, and a young workforce seeking
                  impactful work have transformed the nature of work.
                </p>
                <p className="mb-3">
                  At IMPETROSYS, we align your career goals with strategic
                  skills that benefit everyone in our community. Our
                  Hyperlearning workplace is built on four core principles.
                </p>
                <p>
                  We utilize career-oriented skilling models and harness
                  collective intelligence, supported by technology, to enable
                  continuous learning, unlearning, and relearning at a rapid
                  pace and scale.
                </p>
              </Col>
              <Col lg={6} md={12} data-aos="fade-up">
                <Careerform />
              </Col>
            </Row>
          </div>
        </Container>
      </section>
      <Modal show={show} onHide={handleClose} animation={false}>
        <Modal.Header closeButton>
          <div className="career-close">x</div>
          <Modal.Title>Full Stack Developer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col md={6} sm={6}>
                <label className="Request_field">Name</label>
                <Form.Group className="mb-3">
                  <Form.Control
                    className="form-control"
                    type="text"
                    name="name"
                    placeholder="Enter Your Name"
                    autoComplete="off"
                    value={formik.values.name}
                    onChange={formik.handleChange} />
                  {formik.errors.name && formik.touched.name && (
                    <p className="errormessage">{formik.errors.name}</p>
                  )}
                </Form.Group>
              </Col>
              <Col md={6} sm={6}>
                <label className="Request_field">Email</label>
                <Form.Group className="mb-3">
                  <Form.Control
                    className="form-control"
                    type="email"
                    name="email"
                    placeholder="Enter Your Email"
                    autoComplete="off"
                    value={formik.values.email}
                    onChange={formik.handleChange} />
                  {formik.errors.email && formik.touched.email && (
                    <p className="errormessage">{formik.errors.email}</p>
                  )}
                </Form.Group>
              </Col>
              <Col md={6} sm={6}>
                <label className="Request_field">Phone</label>
                <Form.Group className="mb-3">
                  <Form.Control
                    className="form-control"
                    type="phone"
                    name="phone"
                    country="US"
                    placeholder="Enter Your Mobile Numner"
                    autoComplete="off"
                    value={formik.values.phone}
                    onChange={formik.handleChange} />
                  {formik.errors.phone && formik.touched.phone && (
                    <p className="errormessage">{formik.errors.phone}</p>
                  )}
                </Form.Group>
              </Col>
              <Col md={6} sm={6}>
                <label className="Request_field">Apply for the job</label>
                <Form.Group className="mb-3">
                  <Form.Control
                    className="form-control"
                    type="text"
                    name="job"
                    placeholder="Enter Your Job Title"
                    autoComplete="off"
                    value={titlejob}
                    onChange={(e) => setTitleJob(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col md={12}>
                <div className="field file-upload">
                  <label htmlFor="resume" className="Request_field">Upload Resume</label>
                  <input
                    type="file"
                    className="form-control custom-file-input"
                    ref={fileInputRef}
                    id="uploadfile"
                    onChange={(e) => {
                      upload(e);
                    }} />
                </div>
              </Col>
              <Col md={12}>
                <label className="Request_field">Message</label>
                <Form.Control
                  className="form-control"
                  as="textarea"
                  placeholder="Enter Your Message"
                  style={{ height: "125px" }}
                  type="message"
                  name="message"
                  autoComplete="off"
                  rows={4}
                  value={formik.values.message}
                  onChange={formik.handleChange}
                />
                {formik.errors.message && formik.touched.message && (
                  <p className="errormessage">{formik.errors.message}</p>
                )}
              </Col>
            </Row>
            <div className="form-bt mt-4">
              <div className="mt-3 loader-box">
                {promiseInProgress ? (
                  <div className="spinner flex justify-center items-center h-12">
                    <ThreeDots
                      height="50"
                      width="50"
                      color="#ffb430"
                      ariaLabel="circles-loading"
                      visible={true}
                    />
                  </div>
                ) : (
                  <Button type="Submit" className="carrer-btn" style={{ width: "200px" }}>
                    APPLY NOW
                  </Button>
                )}
              </div>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Careers;
