import React, { useState } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import logo from "../../assets/images/logo.png";
import "./Navbar.css";
import { Link, NavLink } from 'react-router-dom';
import { CiMenuFries } from "react-icons/ci";
import { RxCross1 } from "react-icons/rx";
const ResponsiveNavbar = () => {
  const [navbarExpanded, setNavbarExpanded] = useState(false);

  const handleToggle = () => {
    setNavbarExpanded(!navbarExpanded);
  };
  return (
    <Navbar bg="dark" variant="dark" expand="lg" className="header" expanded={navbarExpanded}>
      <Container fluid>
        <Navbar.Brand href="/">
          <img
            src={logo}
            alt="Logo"
            className="h-logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarCollapse" onClick={handleToggle}>
          {navbarExpanded ? (
            <span style={{ fontSize: '30px', color: '#fff' }}><RxCross1 /></span>
          ) : (
            <span style={{ fontSize: '30px', color: '#fff' }}><CiMenuFries /></span>
          )}
        </Navbar.Toggle>
        <Navbar.Collapse id="navbarCollapse">
          <Nav className="mx-auto">
            <NavLink
              to="/technology"
              className={({ isActive }) => "h-link" + (isActive ? " active" : "")}>
              Technologies
            </NavLink>

            <NavLink
              to="/services"
              className={({ isActive }) => "h-link" + (isActive ? " active" : "")}>
              Services
            </NavLink>

            <NavLink
              to="/products"
              className={({ isActive }) => "h-link" + (isActive ? " active" : "")}>
              Products
            </NavLink>

            <NavLink
              to="/portfolio"
              className={({ isActive }) => "h-link" + (isActive ? " active" : "")}>
              Portfolio
            </NavLink>

            <NavLink
              to="/culture"
              className={({ isActive }) => "h-link" + (isActive ? " active" : "")}>
              Culture at IMPETROSYS
            </NavLink>
          </Nav>

          <Nav className="ms-lg-4">
            <Link to="/hire-experts" className='hire-btn'>Hire Experts</Link>
          </Nav>

          <div className="d-flex align-items-lg-center mt-3 mt-lg-0">
            <Link to="/request-a-quote" className='header-btn'>
              Request a Quote
            </Link>
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default ResponsiveNavbar;
