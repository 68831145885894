import app6 from '../../assets/images/portfolio/app6.png';
import app11 from '../../assets/images/portfolio/app11.png';
import app4 from '../../assets/images/portfolio/app4.png';
import app8 from '../../assets/images/portfolio/app8.png';
import app14 from '../../assets/images/portfolio/app14.png';
import app14image0 from '../../assets/images/portfolio/app14image0.png';
import app14image1 from '../../assets/images/portfolio/app14image1.png';
import app14image2 from '../../assets/images/portfolio/app14image2.png';
import app15 from '../../assets/images/portfolio/app15.png';
import app15image0 from '../../assets/images/portfolio/app15image0.png';
import app15image1 from '../../assets/images/portfolio/app15image1.png';
import app15image2 from '../../assets/images/portfolio/app15image2.png';
import app16 from '../../assets/images/portfolio/app16.png';
import app16image0 from '../../assets/images/portfolio/app16image0.png';
import app16image1 from '../../assets/images/portfolio/app16image1.png';
import app16image2 from '../../assets/images/portfolio/app16image2.png';
import app17 from '../../assets/images/portfolio/app17.png';
import app17image0 from '../../assets/images/portfolio/app17image0.png';
import app17image1 from '../../assets/images/portfolio/app17image1.png';
import app17image2 from '../../assets/images/portfolio/app17image2.png';
import app17image3 from '../../assets/images/portfolio/app17image3.png';
import app18 from '../../assets/images/portfolio/app18.png';
import app19 from '../../assets/images/portfolio/app19.png';
import app19image0 from '../../assets/images/portfolio/app19image0.png';
import app19image1 from '../../assets/images/portfolio/app19image1.png';
import app19image3 from '../../assets/images/portfolio/app19image3.png';
import app20 from '../../assets/images/portfolio/app20.png';
import website1 from '../../assets/images/portfolio/website1.png';
import website3 from '../../assets/images/portfolio/website3.png';
import website6 from '../../assets/images/portfolio/website6.png';
import website7 from '../../assets/images/portfolio/website7.png';
import website9 from '../../assets/images/portfolio/website9.png';
import website11 from '../../assets/images/portfolio/website11.png';
import website12 from '../../assets/images/portfolio/website12.png';
import website13 from '../../assets/images/portfolio/website13.png';
import website14 from '../../assets/images/portfolio/website14.png';
import website17 from '../../assets/images/portfolio/website17.png';
import website18 from '../../assets/images/portfolio/website18.png';
import website19 from '../../assets/images/portfolio/website19.png';
import website20 from '../../assets/images/portfolio/website20.png';
const MenuItems = [
    {
        id: 1,
        image: website3,
        name: "eCommerce Websites and Apps (upto 3-5)",
        category: "Website",
        gallery: [website3, website6, website14],
        // description: 'Android App -> iOS App -> Website'
    },
    {
        id: 2,
        image: website14,
        name: "On-Demand Service Websites and Apps (2-3)",
        category: "Website",
        gallery: [website14],
    },
    {
        id: 3,
        image: website6,
        name: "SoundChatRadio Website and Apps",
        category: "Website",
        gallery: [website6],
  
    },
    {
        id: 4,
        image: website13,
        name: "RamedEventSpace",
        category: "Website",
        gallery: [website13],
  
    },
    {
        id: 5,
        image: website12,
        name: "Global max radio live",
        category: "Website",
        gallery: [website12],
     
    },
    {
        id: 6,
        image: website7,
        name: "Transport management",
        category: "Website",
        gallery: [website7],
       
    },
    {
        id: 7,
        image: website9,
        name: "CA Consultants Website",
        category: "Website",
        gallery: [website9],
   
    },

    {
        id: 8,
        image: website1,
        name: "NGO Website Pmss",
        category: "Website",
        gallery: [website1],
        
    },
    {
        id: 9,
        image: app6,
        name: "Family FM App",
        category: "app",
        gallery: [app6],
    },


    {
        id: 10,
        image: app8,
        name: "RockVibe App",
        category: "app",
        gallery: [app8],
      
    },
    {
        id: 11,
        image: app4,
        name: "Catholic Church App",
        category: "app",
        gallery: [app4],
      
    },
    {
        id: 12,
        image: app11,
        name: "SoundChatRadio App",
        category: "app",
        gallery: [app11],
      
    },
    {
        id: 13,
        image: app20,
        name: "eBooks for kids App ",
        category: "app",
        gallery: [app20],
     
    },
    {
        id: 14,
        image: app14,
        name: "Astrology App",
        category: "app",
        gallery: [app14,app14image0,app14image1,app14image2],
     
    },
     {
        id: 15,
        image: app15,
        name: "Fantasy App",
        category: "app",
        gallery: [app15,app15image0,app15image1,app15image2],
     
    },
    {
        id: 16,
        image: app16,
        name: "Taxi Booking App",
        category: "app",
        gallery: [app16,app16image0,app16image1,app16image2],
    
    },
     {
        id: 17,
        image: app17,
        name: "Hotel Booking App",
        category: "app",
        gallery: [app17,app17image0,app17image1,app17image2,app17image3],
        
    },
    {
        id: 18,
        image: website17,
        name: " Hotel Booking Website and Apps",
        category: "Website",
        gallery: [website17],
      
    },
     {
        id: 19,
        image: website18,
        name: " Taxi Booking Website and Apps",
        category: "Website",
        gallery: [website18],
    
    },
      {
        id: 20,
        image: app18,
        name: "On-Demand Service Apps",
        category: "app",
        gallery: [app18],
       
    },
     {
        id: 21,
        image: app19,
        name: "eCommerce Apps",
        category: "app",
        gallery: [app19,app19image0,app19image1,app19image3],
       
    },
    {
        id: 22,
        image: website11,
        name: "Inventory and POS",
        category: "Website",
        gallery: [website11],
       
    },
    {
        id: 23,
        image: website19,
        name: "Project management system",
        category: "Website",
        gallery: [website19],
    },
    {
        id: 24,
        image: website20,
        name: "Payroll management System",
        category: "Website",
        gallery: [website20],
      
    },
   
  
    
];



export default MenuItems;