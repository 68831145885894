import React, { useState, useEffect } from "react";
import "aos/dist/aos.css";
import AOS from "aos";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { Container, Row, Col } from "react-bootstrap";
import shape from "../../assets/images/shape.png";
import MenuItems from "./MenuItems"; // Import your menu items
import "./Portfolio.css";
import app1 from "../../assets/images/portfolio/app1.png";
import app9 from "../../assets/images/portfolio/app9.png";
import app2 from "../../assets/images/portfolio/app2.png";
import app2image0 from "../../assets/images/portfolio/app2image0.png";
import app2image1 from "../../assets/images/portfolio/app2image1.png";

import app7 from "../../assets/images/portfolio/app7.png";
import app10 from "../../assets/images/portfolio/app10.png";
import app13 from "../../assets/images/portfolio/app13.png";
import app13image0 from "../../assets/images/portfolio/app13image0.png";
import app13image1 from "../../assets/images/portfolio/app13image1.png";
import app13image2 from "../../assets/images/portfolio/app13image2.png";
import app13image3 from "../../assets/images/portfolio/app13image3.png";

import app10image1 from "../../assets/images/portfolio/app10image1.png";
import app9image0 from "../../assets/images/portfolio/app9image0.png";
import app9image1 from "../../assets/images/portfolio/app9image1.png";
import app1image0 from "../../assets/images/portfolio/app1image0.png";
import app1image1 from "../../assets/images/portfolio/app1image1.png";
import app1image2 from "../../assets/images/portfolio/app1image2.png";
import app1image3 from "../../assets/images/portfolio/app1image3.png";
import app1image4 from "../../assets/images/portfolio/app1image4.png";

function Portfolio() {
  // State for featured lightbox
  const [lightboxIndexFeatured, setLightboxIndexFeatured] = useState(-1);

  // State for industry standard projects lightbox
  const [lightboxIndexStandard, setLightboxIndexStandard] = useState(-1);

  // State for dynamic filtering
  const [items, setItems] = useState(MenuItems);
  const [filters, setFilters] = useState({
    all: "btn65",
    web: "btn66",
    app: "btn66",
  });

  // Featured project images
  const featuredImages = [
    {
      src: app13,
      alt: "Crypto Currency Trading and Exchange Platform",
      gallery: [app13, app13image0,app13image1,app13image2,app13image3 ],
    },
    {
      src: app1image0,
      alt: "Fintech Application (BCTPay and Payroos)",
      gallery: [app1image0,app1image1,app1image2,app1image3,app1image4],
    },
    {
      src: app9image1,
      alt: "Event Booking Platform",
      gallery: [app9image1,app9image0,app9],
    },
    {
      src: app2,
      alt: "Banking and Insurance Solution",
      gallery: [app2,app2image0,app2image1],
    },
    
    {
      src: app7,
      alt: "Online Auction Platform",
      gallery: [app7],
    },
    {
      src: app10image1,
      alt: "CMMS ERP",
      gallery: [app10image1,app10],
    },
  ];

  // Initialize AOS
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  // Filter projects
  const filterItem = (category) => {
    if (category === "All") {
      setItems(MenuItems);
    } else {
      const filteredItems = MenuItems.filter((item) => item.category === category);
      setItems(filteredItems);
    }

    setFilters({
      all: category === "All" ? "btn65" : "btn66",
      web: category === "Website" ? "btn65" : "btn66",
      app: category === "app" ? "btn65" : "btn66",
    });
  };

  return (
    <>
      <div className="portfolio-page pt-120 pb-120 texture-bg">
        <div className="top-wave-shape mt-n1">
          <img src={shape} className="w-100" alt="shape" />
        </div>
        <Container>
          <Row>
            {/* Featured Projects */}
            <Col lg={12}>
              <div className="allproject-heading" data-aos="fade-down">
                <h4 className="display-four text-center tcp-1">Our Work Portfolio</h4>
                <h5>Showcasing Our Distinctive Projects</h5>
                <p>
                  We specialize in delivering dynamic, interactive, and
                  user-friendly projects that set us apart and exceed our
                  clients' expectations.
                </p>
              </div>
            </Col>

            <Col lg={12}>
              <h3 className="pro">Featured Projects:</h3>
              <Row>
                {featuredImages.map((image, index) => (
                  <Col lg={4} key={index}>
                    <div
                      className="frame mt-4"
                      onClick={() => setLightboxIndexFeatured(index)}
                    >
                      <div className="item-image">
                        <img src={image.src} alt={image.alt} className="portfolio" />
                      </div>
                      <div className="fram-details">
                        <h1>{image.alt}</h1>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
              <Lightbox
                open={lightboxIndexFeatured !== -1}
                close={() => setLightboxIndexFeatured(-1)}
                slides={
                  lightboxIndexFeatured !== -1
                    ? featuredImages[lightboxIndexFeatured].gallery.map((src) => ({
                        src,
                        title: featuredImages[lightboxIndexFeatured].alt,
                      }))
                    : []
                }
                render={{
                  slide: ({ slide }) => (
                    <div style={{ position: "relative", textAlign: "center" }}>
                      <img src={slide.src} alt={slide.title} style={{
                          Width: "100%", 
                          Height: "95vh", 
                          
                        }}/>
                     
                        <h2 className="new-title-model">{slide.title}</h2>
                      </div>
                   
                  ),
                }}
              />
            </Col>

            <hr className="mt-5" />

            {/* Industry Standard Projects */}
            <Col lg={12}>
              <h3 className="pro">Industry Standard Projects:</h3>
              <div className="filters" data-aos="fade-right">
                <div className="filter-menu mb-3">
                  <button
                    className={filters.all}
                    onClick={() => filterItem("All")}
                  >
                    All
                  </button>
                  <button
                    className={filters.web}
                    onClick={() => filterItem("Website")}
                  >
                    Website
                  </button>
                  <button
                    className={filters.app}
                    onClick={() => filterItem("app")}
                  >
                    Mobile App
                  </button>
                </div>
              </div>

              <Row>
                {items.map((item, index) => (
                  <Col lg={4} key={index}>
                    <div
                      className="frame mt-4"
                      onClick={() => setLightboxIndexStandard(index)}
                    >
                      <div className="item-image">
                        <img src={item.image} alt={item.name} className="portfolio" />
                      </div>
                      <div className="fram-details">
                        <h1>{item.name}</h1>
                        <p>{item.description}</p>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
              <Lightbox
                open={lightboxIndexStandard !== -1}
                close={() => setLightboxIndexStandard(-1)}
                slides={
                  lightboxIndexStandard !== -1
                    ? items[lightboxIndexStandard].gallery.map((src) => ({
                        src,
                        title: items[lightboxIndexStandard].name,
                      }))
                    : []
                }
                render={{
                  slide: ({ slide }) => (
                    <div style={{ position: "relative", textAlign: "center" }}>
                      <img src={slide.src} alt={slide.title} style={{
                          Width: "100%", 
                          maxHeight: "95vh", }}/>
                      
                        <h2 className="new-title-model">{slide.title}</h2>
                     
                    </div>
                  ),
                }}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Portfolio;
