import React, { useState, useEffect, useRef } from "react";
import CustomSelect from "./CustomSelect";
import "./Contact.css";
import DatePicker from "react-datepicker";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "react-datepicker/dist/react-datepicker.css";
// import { FaEnvelopeOpenText } from "react-icons/fa";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { Container, Row, Col, Form } from "react-bootstrap";
import axios from "axios";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import { ThreeDots } from "react-loader-spinner";
function Rqform() {
  //loder
  const { promiseInProgress } = usePromiseTracker();

  // state
  const [startDate, setStartDate] = useState(new Date());
  const [servicesdata, setServicesdata] = useState([])
  const [resumefile, setResumefile] = useState(null);
  // Create a ref for the file input
  const fileInputRef = useRef(null);

  const options4 = [
    { value: "India", title: "India" },
    { value: "United States", title: "United States" },
    { value: "Japan", title: "Japan" },
    { value: "Oman", title: "Oman" },
    { value: "Qatar", title: "Qatar" },
    { value: "United Kingdom", title: "United Kingdom" },
    { value: "Zimbabwe", title: "Zimbabwe" },
    { value: "Italy", title: "Italy" },
    { value: "Brazil", title: "Brazil" },
    { value: "France", title: "France" },
  ];

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/getservices`)
      .then((response) => {
        if (response.status == 200) {
          setServicesdata(response.data.serviceslist)
        }
      })
      .catch((error) => {
        console.log(error);
      })
  }, [])

  const mobilevalidation = (mobile) => {
    if (!new RegExp(/^[0-9+].{6,12}$/).test(mobile)) {
      // setmobilevalidationerror("Please Enter A valid Mobile Number");
      formik.setFieldError("phone", "Invalid phone number");
      // setmobilevalid(false);
    } else {
      // setmobilevalidationerror("");
      formik.setFieldError("phone", "");
      // setmobilevalid(true);
    }
  };


  const phonevalidation = (mobile) => {
    if (!new RegExp(/^[0-9+].{6,12}$/).test(mobile)) {
      // setphonevalidationerror("Please Enter A valid Mobile Number");
      formik.setFieldError("whatsapp", "Invalid whatsapp number");
      // setphonevalid(false);
    } else {
      // setphonevalidationerror("");
      formik.setFieldError("whatsapp", "");
      // setphonevalid(true);
    }
  };


  const showAlert = (message) => {
    Swal.fire({
      title: "Thank You !",
      text: message,
      icon: "success",
      confirmButtonText: "OK",
    });
  };

  // toast message function
  const showToastMessage = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const showToastMessageone = (data) => {
    toast.error(data, {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  //this is uses for the pdf/image upload in database.

  const upload = (event) => {
    const imageFile = event.target.files[0];
    setResumefile(imageFile);
    if (!imageFile) {
      showToastMessage("Please select a file");
      return false;
    } else if (!imageFile.name.match(/\.(doc|docx|pdf|txt)$/)) {
      showToastMessage("Please select only .pdf, .txt, .doc");
      return false;
    }
  };

  // api calling for request a quotation
  function handleSubmit(values) {
    const bodyFormData = new FormData();
    bodyFormData.append("name", values.name);
    bodyFormData.append("email", values.email);
    bodyFormData.append("phone", values.phone);
    bodyFormData.append("whatsapp", values.whatsappno);
    bodyFormData.append("description", values.description);
    bodyFormData.append("service_type", values.projecttype);
    bodyFormData.append("country", values.country);
    bodyFormData.append("file", resumefile);
    bodyFormData.append("start_date", startDate);
    const url = `${process.env.REACT_APP_BASE_URL}/requestprojectquotation`;
    trackPromise(
      axios
        .post(url, bodyFormData, {
          "Content-Type": "multipart/form-data",
          headers: { Accept: "application/json" },
        })
        .then((response) => {
          if (response.data.status_code === 200) {
            showAlert(response.data.message)
            setResumefile(null)
            setStartDate("")
            if (fileInputRef.current) {
              fileInputRef.current.value = null;
            }
          }
          else if (response.data.status_code === 201) {
            showToastMessageone(response.data.message.name);
            showToastMessageone(response.data.message.email);
            showToastMessageone(response.data.message.whatsapp);
            showToastMessageone(response.data.message.phone);
            showToastMessageone(response.data.message.description);
            showToastMessageone(response.data.message.file);
            showToastMessageone(response.data.message.start_date);
            showToastMessageone(response.data.message.country);
            showToastMessageone(response.data.message.service_type);
          }
        })
        .catch((error) => {
          console.log(error)
        })
    )
  }

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      description: "",
      phone: "",
      whatsappno: "",
      projecttype: "",
      country: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .matches(/^[A-Za-z\s]+$/, "Only Enter Alphabet")
        .required("Required!"),

      email: Yup.string()
        .email("Invalid email format")
        .matches(/^\S*$/, "Empty Whitespace Not Allowed")
        .required("Required!"),

      description: Yup.string()
        .required("Required!"),

      phone: Yup.string().required("Phone number is required!"),

      whatsappno: Yup.string().required("Whatsapp number is required!"),

      projecttype: Yup.string().required("You must select an project type!"),

      country: Yup.string().required("You must select an country!"),
    }),
    onSubmit: (values) => {
      setResumefile(null)
      setStartDate("")

      if (fileInputRef.current) {
        fileInputRef.current.value = null;
      }
      handleSubmit(values);
      formik.resetForm();
    },
  });


  return (
    <div className="contactrequest-12">
      <Container>
        <div className="form-t11 mb-4">
          <p>
            Share a few details about your project, and we will prepare a proposal for you within <strong>24</strong> hours.
          </p>
        </div>
        <div className="form-contact">
          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col md={6}>
                <div className="rqfrom-us-new">
                  <label className="Request_field">Enter Full Name</label>
                  <Form.Group>
                    <Form.Control
                      placeholder="Enter Your Name"
                      type="text"
                      name="name"
                      autoComplete="off"
                      value={formik.values.name}
                      onChange={formik.handleChange} />
                    {formik.errors.name && formik.touched.name && (
                      <p className="errormessage">{formik.errors.name}</p>
                    )}
                  </Form.Group>
                </div>
              </Col>
              <Col md={6}>
                <div className="rqfrom-us-new">
                  <label className="Request_field">
                    Enter Email Address
                  </label>
                  <Form.Group>
                    <Form.Control
                      placeholder="Enter Your Email"
                      type="email"
                      name="email"
                      autoComplete="off"
                      value={formik.values.email}
                      onChange={formik.handleChange} />
                    {formik.errors.email && formik.touched.email && (
                      <p className="errormessage">{formik.errors.email}</p>
                    )}
                  </Form.Group>
                </div>
              </Col>

              <Col md={6}>
                <div className="rqfrom-us-new">
                  <label className="Request_field">
                    Enter Mobile Number
                  </label>
                  <PhoneInput
                    country={"in"}
                    value={formik.values.phone}
                    onChange={(phone) => {
                      formik.setFieldValue("phone", phone);
                      mobilevalidation(phone);
                    }}
                    inputProps={{
                      name: "phone",
                      required: true,
                      autoFocus: false,
                      style: { marginLeft: "0px", width: "100%" },
                    }}
                  />
                  {formik.errors.phone && formik.touched.phone ? (
                    <p className="errormessage">{formik.errors.phone}</p>
                  ) : null}
                </div>
              </Col>

              <Col md={6}>
                <div className="rqfrom-us-new">
                  <label className="Request_field">
                    Enter Whatsapp Number
                  </label>

                  <PhoneInput
                    country={"in"}
                    value={formik.values.whatsappno}
                    onChange={(whatsappno) => {
                      formik.setFieldValue("whatsappno", whatsappno);
                      phonevalidation(whatsappno);
                    }}
                    inputProps={{
                      name: "whatsappno",
                      required: true,
                      autoFocus: false,
                      style: { marginLeft: "0px", width: "100%" },
                    }}
                  />
                  {formik.errors.whatsappno && formik.touched.whatsappno ? (
                    <p className="errormessage">{formik.errors.whatsappno}</p>
                  ) : null}
                </div>
              </Col>

              <Col md={6}>
                <div className="rqfrom-us-new">
                  <Form.Group>
                    <label htmlFor="projectType" className="mb-2">Project Type</label>
                    <CustomSelect
                      options={servicesdata}
                      placeholder="Project Type"
                      selectedOption={formik.values.projecttype} // Bind Formik's value
                      onChange={(value) => formik.setFieldValue('projecttype', value.title)}
                    />
                    {formik.errors.projecttype && formik.touched.projecttype ? (
                      <p className="errormessage">{formik.errors.projecttype}</p>
                    ) : null}
                  </Form.Group>
                </div>
              </Col>

              <Col md={6} >
                <div className="rqfrom-us-new">
                  <div className="start_date">
                    <label className="Request_field">
                      When you expected to start
                    </label>
                    {/* <DatePicker
                      className="form-control"
                      selectsStart
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      startDate={startDate}
                      required={true} /> */}
                      <DatePicker
                        className="form-control"
                        selectsStart
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        startDate={startDate}
                        minDate={new Date()} // Prevents selection of dates earlier than today
                        placeholderText={!startDate ? "Select a start date" : ""} // Placeholder shows when no date is selected
                        required
                      />
                  </div>
                </div>
              </Col>

              <Col md={6}>
                <div className="rqfrom-us-new">
                  <Form.Group>
                    <label htmlFor="country" className="mb-2">Please Select You Country</label>
                    <CustomSelect
                      placeholder="Country"
                      options={options4}
                      selectedOption={formik.values.country} // Bind Formik's value
                      onChange={(value) => formik.setFieldValue('country', value.title)}
                    />
                    {formik.errors.country && formik.touched.country ? (
                      <p className="errormessage">{formik.errors.country}</p>
                    ) : null}
                  </Form.Group>
                </div>
              </Col>
              <Col md={6}>
                <div className="rqfrom-us-new">
                  <div className="field file-upload">
                    <label htmlFor="request" className="Request_field">
                      Upload Your Requirement
                    </label>
                    <input
                      type="file"
                      className="form-control custom-file-input"
                      id="uploadfile"
                      ref={fileInputRef}
                      onChange={(e) => {
                        upload(e);
                      }} />
                  </div>
                </div>
              </Col>
              <Col md={12}>
                <div className="rqfrom-us-new">
                  <label className="Request_field">Share some details related to project</label>
                  <Form.Control
                    as="textarea"
                    style={{ height: "100px" }}
                    placeholder="Enter your description"
                    name="description"
                    className="form-control"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    type="message"
                    autoComplete="off" />
                  {formik.errors.description && formik.touched.description && (
                    <p className="errormessage">{formik.errors.description}</p>
                  )}
                </div>
              </Col>
              <Col md={12}>
                <div className="contact-7">
                  <div className="loader-box mt-3">
                    {promiseInProgress ? (
                      <div className="spinner flex justify-center items-center h-12">
                        <ThreeDots
                          height="60"
                          width="60"
                          color="#ffb430"
                          ariaLabel="circles-loading"
                          visible={true}
                        />
                      </div>
                    ) : (
                      <button
                        type="submit"
                        className="main-btn-resuest" >
                        Submit your request
                      </button>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </Container>
    </div>
  );
}

export default Rqform;
