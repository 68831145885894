
import React, { useState, useEffect, useRef } from "react";
import "./Contact.css";

const CustomSelect = ({ options, placeholder, selectedOption, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef();

  const handleSelectOption = (value) => {
    setIsOpen(false);
    if (onChange) {
      onChange(value); // Pass value to the parent component
    }
  };

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (e) => {
    if (selectRef.current && !selectRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <div className="custom-select-wrapper" ref={selectRef}>
      <div className={`custom-select ${isOpen ? "opened" : ""}`}>
        <span className="custom-select-trigger" onClick={handleToggle}>
          {selectedOption || placeholder}
        </span>
        <div className={`custom-options ${isOpen ? "open" : ""}`}>
          {options.map((option, index) => (
            <span
              key={index}
              className={`custom-option ${
                option.title === selectedOption ? "selection" : ""
              }`}
              onClick={() => handleSelectOption(option)}
            >
              {option.title}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CustomSelect;
