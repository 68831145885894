import { useEffect, useState } from "react";
import "aos/dist/aos.css";
import AOS from "aos";
import "./Blog.css";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { CiBank } from "react-icons/ci";
import Pagination from "react-bootstrap/Pagination";
import {
  FaLandmark,
  FaStudiovinari,
  FaWpforms,
  FaBone,
  FaCompressArrowsAlt,
  FaDraftingCompass,
  FaLongArrowAltRight,
} from "react-icons/fa";
import career from "../../assets/images/career.png";
import banner1 from "../../assets/images/banner1.png";
import banner3 from "../../assets/images/banner3.png";

const Product = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = 3; // Set total pages

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const paginationItems = [];
  for (let page = 1; page <= totalPages; page++) {
    paginationItems.push(
      <Pagination.Item
        key={page}
        active={page === currentPage}
        onClick={() => handlePageChange(page)}
      >
        {page}
      </Pagination.Item>
    );
  }
  return (
    <>
      <section className="lates-blog pt-120 pb-120 texture-bg-2">
        <Container>
          <Row className="g-6 align-items-center justify-content-between">
            <Col lg={12} data-aos="fade-down">
              <div className="text-center pt-120 mb-5">
                <h4 className="display-four ">
                  <span className="tcp-1">Lates Blog</span>
                  <p>
                    Importance of Momentum Behavior in Conversion Rate
                    Optimization
                  </p>
                </h4>
              </div>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <div
                className="recent-article-card rounded-3 bgc-2 aos-init aos-animate"
                data-aos="zoom-in"
              >
                <div className="img-area mb-3">
                  <img
                    className="blog-img w-100 rounded"
                    src={career}
                    alt="article"
                  />
                </div>
                <div className="content-area">
                  <div className="d-flex align-items-center gap-2 mb-3">
                    <div className="tag-btn-5">Impetrosys</div>
                    <span className="tag-btn-2">6 Oct, 2024</span>
                  </div>
                  <h5>
                    Why Choose Concord ERP Software for Construction Industries?
                  </h5>
                  <p>
                    Concord ERP is the Ideal
                    <strong>ERP Software</strong>
                    for Construction Industries, which consists of different
                    modules that help in overall business operations; automated
                    construction processes, and works on unnecessary downtime so
                    that you can stay on time and on budget, increased
                    productivity.
                  </p>
                  <div className="d-flex align-items-center gap-4">
                    <Link to="/blog-details" className="link-btn text-nowrap">
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <div
                className="recent-article-card rounded-3 bgc-2 aos-init aos-animate"
                data-aos="zoom-in">
                <div className="img-area mb-3">
                  <img
                    className="blog-img w-100 rounded"
                    src={banner1}
                    alt="article" />
                </div>
                <div className="content-area">
                  <div className="d-flex align-items-center gap-2 mb-3">
                    <div className="tag-btn-5">Impetrosys</div>
                    <span className="tag-btn-2">7 Oct, 2024</span>
                  </div>
                  <h5>ERP Software for HR Management</h5>
                  <p>
                    Fully integrated and comprehensive HR Management ERP
                    Software, that manages all aspects of Human Resources.
                  </p>
                  <p>
                    Human resource administration and management in any industry
                    is never a simple task but when it comes to construction it
                    becomes more complex and difficult to manage, with different
                    sets of challenges in the highly mobile and unstable nature
                  </p>
                  <div className="d-flex align-items-center gap-4">
                    <Link to="/blog-details" className="link-btn text-nowrap">
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <div
                className="recent-article-card rounded-3 bgc-2 aos-init aos-animate"
                data-aos="zoom-in">
                <div className="img-area mb-3">
                  <img
                    className="blog-img w-100 rounded"
                    src={banner3}
                    alt="article" />
                </div>
                <div className="content-area">
                  <div className="d-flex align-items-center gap-2 mb-3">
                    <div className="tag-btn-5">Impetrosys</div>
                    <span className="tag-btn-2">8 Oct, 2024</span>
                  </div>
                  <h5>Manages The Customer Lifecycle of An Organization</h5>
                  <p>
                    CRM Software enables your marketing, sales, and customer
                    services teams to work together, to share information, and
                    to manage every interaction with prospects, leads, and
                    customers, these are the seven most useful features in CRM
                    software for small and medium businesses.
                  </p>
                  <div className="d-flex align-items-center gap-4">
                    <Link to="/blog-details" className="link-btn text-nowrap">
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <div className="mt-5">
            <Pagination>
              <Pagination.First onClick={() => handlePageChange(1)} />
              <Pagination.Prev
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              />
              {paginationItems}
              <Pagination.Next
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              />
              <Pagination.Last onClick={() => handlePageChange(totalPages)} />
            </Pagination>
            
          </div>
        </Container>
      </section>
    </>
  );
};

export default Product;
